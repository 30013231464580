import React from "react";
import 'react-bootstrap';
import { Helmet } from "react-helmet"
import SideBar    from "./sidebar"
import "../styles/bootstrap.css"
import Nav from "./navTrap2"
import '../styles/custom.css'
import '../styles/nav.css'
import Footer from "./footer"

/*Audit erfuellen: <html lang="de" /> , <main... statt <div> */
const Layout = ({children, head_gif}) => (
	<div id="wrapper">
		<Helmet
			title="Ferien in Mariensiel"
		  meta={[
				{ name: 'charSet', content: 'utf-8' },
				{ name: 'lang', content: 'de' },
				{ name: 'docsearch:version', content: '2.0' },
				{ name: 'viewport', content: 'width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover' },
				{ name: 'title', content: 'Ferien in Mariensiel' },
				{ name: 'author', content: 'Kai Dirks' },
				{ name: 'copyright', content: 'Kai Dirks 2022' },
				{ name: 'keywords', content: 'Ferien, Urlaub, Ferienwohnung, Meierhoff, Sielweg, Mieten, Mariensiel, Nordsee, südliche Nordsee, Jadebusen, Friesland, Wilhelmshaven' },
				{ name: 'description', content: 'Ferienwohnung in Mariensiel bei Familie Meierhoff am Jadebusen' },
				{ name: 'abstract', content: 'Ferienwohnung in Mariensiel bei Familie Meierhoff am Jadebusen' },
				{ name: 'url', content: 'https://www.meierhoff_Ferien.de' },
				{ name: 'siteUrl', content: 'https://www.meierhoff_Ferien.de' }
		  ]}>
			<html lang="de" />
    </Helmet>
   	<Nav />
		<div className="row">
		  <SideBar head_gif={head_gif} />
			<div className="col-sm-12 col-md-9">{children}</div>
		</div>
 	  <Footer />
	</div>
)
export default Layout;
