import React       from 'react';
import {Link}      from "gatsby";
import home        from "../images/nav/home_n.gif"
import attractions from "../images/nav/seeblick.gif"
import rooms 			 from "../images/nav/wohnung.gif"
import facilities  from "../images/nav/gallerie.gif"
import buchung     from "../images/nav/buchung.gif"
import location    from "../images/nav/anfahrt.gif"
import impressum   from "../images/nav/impressum.gif"
import Bild        from "../components/myImage4"

const ListLink = props => (
  <Link to={props.to}><img src={props.src} alt="sidebar" /></Link>
)
/* Audit erfuellen: aria-label, da hier statt Textlink(wie im Kopfnav) ein Bildlink */
const Sidebar = ({head_gif}) => (
  <div className="col-sm-0 col-md-3 sidebar">
    <Bild my_img={head_gif}/>
    <ListLink to="/"             src={home}        aria-label="home"       />
    <ListLink to="/attractions/" src={attractions} aria-label="attractions"/>
    <ListLink to="/rooms/"       src={rooms}       aria-label="rooms"      />
    <ListLink to="/gallerie/"    src={facilities}  aria-label="facilities" />
    <ListLink to="/buchung/"     src={buchung}     aria-label="buchung"    />
    <ListLink to="/location/"    src={location}    aria-label="location"   />
    <ListLink to="/impressum/"   src={impressum}   aria-label="impressum"  />
    <br /><br />
     <p style={{marginBottom:"-7px"}}>Besucher:<br /></p>
     <img src="https://www.counter-free.eu/counter/gruengrauer_counterframe-2401.jpg" alt="counter" width="120" height="35"/>
  </div>
)

export default Sidebar;
