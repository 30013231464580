//https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
import React from 'react';
import Img   from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby';
/* fluid(maxWidth: 668) bestimmt, mit welcher Aufloesung das Bild im gesetzten Rahmen erscheinen soll
   ist der Rahmen groesser als der Wert, erscheint das Bild verpixelt
   maxWidth kann auch entfallen, ohne Nachteile ?
        imageSharp(fluid: {originalName: {eq: "home_bhb.png"}}) {
    ($my_img: String!)
    <Img fluid={imageSharp.fluid} alt={my_img} />
*/
const MyImage = ({ my_img, my_txt }) => {
  const { allImageSharp } = useStaticQuery(
    graphql`
    {
    allImageSharp {
      edges {
        node {
          fluid {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  }
  `);
    return (
      <div className="row">
        {my_txt}
        <Img fluid={allImageSharp.edges.find(edge => edge.node.fluid.originalName === my_img).node.fluid} alt={my_img} />        
      </div>
    )
 }
export default MyImage;
