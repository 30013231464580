import React from 'react';
import {Link} from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faFax, faEnvelope } from '@fortawesome/free-solid-svg-icons'
//https://github.com/FortAwesome/react-fontawesome#installation

const ListLink = props => (
  <ul><li style={{ display: "inline-block", alignSelf:"center" }}>
    <Link to={props.to}><p>{props.children}</p></Link>
  </li></ul>
)

const Foot = () => (
	<section className="panel-footer" id="footer">
    <div className="container">
      <div className="col-12 col-md-4">
        <p>Meierhoff, Ferdinand & Jutta<br />
					Sielweg 5<br />
        26452 Mariensiel</p>
      </div>
      <div className="col-12 col-md-5">
        <p><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;<a href="mailto:fmeierhoff@gmx.de">fmeierhoff@gmx.de</a></p>
        <p><FontAwesomeIcon icon={faPhone} /><span> +49 (0)4421-202181</span></p>
        <FontAwesomeIcon icon={faFax} /><span style={{fontSize:"20px"}}>&nbsp;&nbsp; +49 (0)4421-202181</span>
      </div>
      <div className="col-12 col-md-3 footLinks">
        <ListLink to="/impressum/">Impressum</ListLink>
        <ListLink to="/datenschutz/">Datenschutz</ListLink>
      </div>
			<div className="row"></div>
      <div align="center">© Copyright 2022 Dipl. Ing. Kai Dirks - Alle Rechte vorbehalten -</div>
		</div>
  </section>
)

export default Foot;
