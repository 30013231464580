import React      from 'react';
import { Link }   from "gatsby";
import { Navbar } from 'react-bootstrap';

const ListLink = props => (
  <li><Link to={props.to}>{props.children}</Link></li>
)
//https://react-bootstrap.netlify.com/components/navbar/
const NavTrap = () => (
  <Navbar className="navbar-fixed-top">
    <Navbar.Toggle />
    <Navbar.Collapse>
      <ul className="nav navbar-nav">
        <ListLink to="/">Home</ListLink>
        <ListLink to="/attractions/">Seeblick</ListLink>
        <ListLink to="/rooms/">Wohnung</ListLink>
        <ListLink to="/gallerie/">Gallerie</ListLink>
        <ListLink to="/buchung/">Buchung</ListLink>
        <ListLink to="/location/">Anfahrt</ListLink>
        <ListLink to="/impressum/">Impressum</ListLink>
      </ul>
    </Navbar.Collapse>
  </Navbar>
)

export default NavTrap;
